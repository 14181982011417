import {Dispatch, SetStateAction} from 'react'

import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import AddLineIcon from 'remixicon-react/AddLineIcon'
import SubtractLineIcon from 'remixicon-react/SubtractLineIcon'

import {styledTheme} from '@festi/common/themes'
import {H4, H5} from '@festi/common/components/typography'
import {useCommonContext} from '@festi/common/contexts/common'
import {DynamicIcon, Expandable} from '@festi/common/components/common'
import {AllPageNavigations_allPageNavigations} from '@festi/common/api/datocms/types/AllPageNavigations'
import settings from '@festi/common/constants/settings'

const redesign = settings.redesign

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-left: ${redesign ? 6 : 0}px;
`

const BorderBottom = styled.div`
  border-bottom: 1px solid ${palette('ui10Solid')};
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${redesign ? 60 : 74}px;
  padding: 0;
`

const NoLink = styled.div`
  width: 100%;
  cursor: pointer;
`

const MainLinkPadding = styled.div`
  display: flex;
  align-items: center;

  .remixicon-icon {
    margin-right: 8px;
  }
`

const ExpandIcon = styled.div`
  padding: 23px 0 23px 20px;
  cursor: pointer;
`

const SublinkBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px 20px 0;
`

const Sublink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: elepsis;
  padding: 8px 0;

  p {
    color: ${palette('blue')};
  }
`

const Circle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${palette('ui20Solid')};
`

interface LinkProps {
  page: AllPageNavigations_allPageNavigations
  active: boolean
  setActive: Dispatch<SetStateAction<string | null>>
  onRequestClose?: () => void
}

function MainLink({page, active, setActive, onRequestClose}: LinkProps) {
  const main = (
    <MainLinkPadding>
      {page?.icon && <DynamicIcon icon={page.icon} size={22} />}
      {redesign ? <H5>{page.heading}</H5> : <H4>{page.heading}</H4>}
    </MainLinkPadding>
  )

  return (
    <LinkBox>
      {page.pageLink?.path ? (
        <Link href={page.pageLink.path} onClick={onRequestClose}>
          {main}
        </Link>
      ) : (
        <NoLink onClick={() => setActive(active ? null : page.id)}>
          {main}
        </NoLink>
      )}

      {!!page.children?.length &&
        (active ? (
          <ExpandIcon onClick={() => setActive(null)}>
            <SubtractLineIcon size={24} color={styledTheme.palette.green} />
          </ExpandIcon>
        ) : (
          <ExpandIcon onClick={() => setActive(page.id)}>
            <AddLineIcon size={24} color={styledTheme.palette.blue} />
          </ExpandIcon>
        ))}
    </LinkBox>
  )
}

interface Props {
  onRequestClose?: () => void
}

export default function PageNavigation({onRequestClose}: Props): JSX.Element {
  const {activeNavItem, pageNavigation, setActiveNavItem} = useCommonContext()

  return (
    <InfoWrapper>
      {pageNavigation?.allPageNavigations?.map((page) => (
        <BorderBottom key={page.id}>
          <MainLink
            page={page}
            active={activeNavItem === page.id}
            setActive={setActiveNavItem}
            onRequestClose={onRequestClose}
          />

          <Expandable expanded={activeNavItem === page.id}>
            <SublinkBox>
              {page?.children?.map((child) => (
                <Sublink
                  key={child?.id}
                  href={child?.pageLink?.path || '/'}
                  onClick={onRequestClose}
                >
                  <Circle />
                  {redesign ? (
                    <p>{child?.heading}</p>
                  ) : (
                    <H5>{child?.heading}</H5>
                  )}
                </Sublink>
              ))}
            </SublinkBox>
          </Expandable>
        </BorderBottom>
      ))}
    </InfoWrapper>
  )
}
