import React, {useCallback} from 'react'

import ReactModal from 'react-modal'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette, ifProp, switchProp} from 'styled-tools'
import {useMedia} from 'react-use'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '../buttons'
import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

export enum ModalSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}
interface CustomModalStyleProps {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

const StyledModal = styled(ReactModal)<Props>`
  background-color: ${(p) => palette(p?.backgroundColor || 'white')};
  overflow: unset;
  outline: none;
  max-width: ${switchProp('size', {
    xs: '500px',
    sm: '800px',
    md: '1024px',
    lg: '1560px',
  })};
  ${ifProp(
    {bottomMobile: true},
    css`
      position: absolute;
      bottom: 0;
      width: 100%;
    `,
    css`
      position: relative;
      top: 32px;
      width: calc(100% - 32px);
    `,
  )}
  z-index: 999999;

  &.topRadius {
    border-radius: 12px 12px 0 0;
  }

  ${media.sm`
    position: relative;
    bottom: unset;
    ${fluidRange('top', '32px', '128px')}
    min-height: unset;
    width: calc(100% - 32px);
    margin-bottom: 118px;
  `}
`

const customStyles: CustomModalStyleProps = {
  overlay: {
    position: 'fixed',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: styledTheme.palette.ui60,
    zIndex: styledTheme.zIndex.modal,
  },
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

export const ModalContentNarrow = styled.div`
  ${fluidRange('padding-top', '32px', '64px')}
  ${fluidRange('padding-bottom', '24px', '80px')}
  ${fluidRange('padding-left', '32px', '120px')}
  ${fluidRange('padding-right', '32px', '120px')}
`

export const ModalContentWide = styled.div`
  ${fluidRange('padding-top', '32px', '64px')}
  ${fluidRange('padding-bottom', '32px', '56px')}
  ${fluidRange('padding-left', '32px', '56px')}
  ${fluidRange('padding-right', '32px', '56px')}
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  ${fluidRange('top', '14px', '12px')}
  ${fluidRange('right', '14px', '12px')}
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
  border-radius: 12px 12px 0 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: 'touch';
`

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 16px;
  padding-block: 14px;
  border-bottom: 1px solid ${styledTheme.palette.ui10Solid};
  background-color: ${palette('white')};
  z-index: 2;
`

const ModalBody = styled.div`
  padding: 16px;
  height: 100%;
  z-index: 1;
`

const Title = styled.h5`
  font-size: 20px;
  font-weight: 500;
`

interface Props extends ReactModal.Props {
  size?: ModalSize
  bottomMobile?: boolean
  darkBackground?: boolean
  backgroundColor?: string
  children?: React.ReactNode
  title?: string
}

export default function Modal({
  size = ModalSize.Small,
  bottomMobile,
  backgroundColor,
  children,
  onRequestClose,
  title,
  ...props
}: Props): JSX.Element {
  const isMobile = useMedia(
    `(max-width: ${styledTheme.screenSize.sizeMD})`,
    false,
  )

  const getBorderRadius = useCallback(() => {
    if (newDesign && !bottomMobile) {
      return 20
    } else if (newDesign && bottomMobile) {
      return isMobile ? '12px 12px 0 0' : 20
    } else {
      return 0
    }
  }, [bottomMobile, isMobile])

  return (
    <StyledModal
      style={{
        ...customStyles,
        ...{
          content: {
            borderRadius: getBorderRadius(),
          },
        },
      }}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      ariaHideApp={false}
      backgroundColor={backgroundColor}
      bottomMobile={bottomMobile}
      size={size}
      {...props}
    >
      {!title && children}
      {!!onRequestClose &&
        (title ? (
          <ModalContentWrapper>
            <ModalHeader>
              <Title>{title}</Title>
              <CloseButton onClick={onRequestClose} />
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
          </ModalContentWrapper>
        ) : (
          <CloseButtonWrapper>
            <CloseButton onClick={onRequestClose} />
          </CloseButtonWrapper>
        ))}
    </StyledModal>
  )
}
