import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '@festi/common/utils/styles'

const Root = styled.div`
  ${fluidRange('width', '36px', '48px')}
  ${fluidRange('height', '36px', '48px')}
  ${fluidRange('min-width', '36px', '48px')}
  ${fluidRange('margin-right', '16px', '24px')}
  background-color: ${palette('ui5Solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .remixicon-icon {
    ${fluidRange('width', '20px', '24px')}
    ${fluidRange('height', '20px', '24px')}
    fill: ${palette('ui40Solid')};
  }
`

interface IconBoxProps {
  icon: RemixiconReactIconComponentType
}

export default function IconBox({icon: Icon}: IconBoxProps): JSX.Element {
  return (
    <Root>
      <Icon />
    </Root>
  )
}
