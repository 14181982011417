import {useCallback} from 'react'

import Link from 'next/link'
import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import sanitizeHtml from 'sanitize-html'
import {media} from 'styled-bootstrap-grid'

import settings from '@festi/common/constants/settings'
import {styledTheme} from '@festi/common/themes'
import {VariantList, VariantDetail} from '@festi/common/api/rest'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {AddToCartButton} from '@festi/common/components/cart'
import {H5, PSmall} from '@festi/common/components/typography'
import {ProductPrices} from '@festi/common/components/cart/ProductPrices'
import {useEvents} from '@festi/common/contexts'
import useTagmanager from '@festi/common/utils/tagmanager'
import {useCommonContext} from '@festi/common/contexts/common'

import ProductTags from './utilities/ProductTags'
import ProductImage from './utilities/ProductImage'
import ProductCardLoader from './utilities/ProductCardLoader'
import ProductActionButtons from './utilities/ProductActionButtons'
import ItemBanners from 'src/components/products/redesign/ItemBanners'

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 12px;
  background-color: ${palette('white')};

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    transition: border-color 300ms ease;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:before {
    border-color: ${palette('ui20Solid')};
  }
`

const ProductLink = styled(Link)`
  width: 100%;
  cursor: ${styledTheme.cursor.pointer};
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 20px 6px;

  .horizontal {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const ProductText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const FunctionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 20px;

  ${media.md`
    max-width: 480px;
  `}

  &.horizontal {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
  }
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;

  &.horizontal {
    padding-bottom: 16px;
  }
`

const ActionButtons = styled.div`
  display: flex;
  gap: 20px;

  &.horizontal {
    width: 100%;
  }
`

const Selling = styled.div`
  display: flex;
  gap: 2px;
  flex-wrap: wrap;

  &.horizontal {
    flex-direction: column;
    gap: 8px;
  }
`

const Sku = styled(PSmall)`
  color: ${palette('ui40Solid')};
  user-select: all;
`

const Point = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: ${palette('ui80Solid')};

  &:not(.horizontal) {
    &:not(:last-of-type) {
      &:after {
        content: '|';
        padding: 0 4px;
        color: ${palette('ui20Solid')};
      }
    }
  }

  &.horizontal {
    padding-left: 16px;

    &:before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${palette('ui20Solid')};
    }
  }

  a {
    color: ${palette('link')};
    font-size: inherit;
    font-weight: inherit;
    cursor: ${styledTheme.cursor.pointer};
  }

  &.isFeatured {
    font-size: 1.2rem;
  }
`

const Relative = styled.div`
  position: relative;
`

interface SellingPointProps {
  sellingPoints: string[]
  horizontal?: boolean
  isFeatured?: boolean
}

export function SellingPoint({
  sellingPoints,
  horizontal = false,
  isFeatured = false,
}: SellingPointProps): JSX.Element {
  const limit = 3

  if (!sellingPoints?.length) return null

  return (
    <Selling className={classNames({horizontal})}>
      {sellingPoints?.slice(0, limit)?.map((point, index) => (
        <Point
          key={index}
          dangerouslySetInnerHTML={{__html: sanitizeHtml(point)}}
          className={classNames({horizontal, isFeatured})}
        />
      ))}
    </Selling>
  )
}

export type GridType = 'normal' | 'small' | 'list'

interface Props {
  image: {
    url: string
    alt: string
  }
  origin?: string
  variant?: VariantList | VariantDetail
  gridType?: GridType
  position?: number
  userPrice?: AugmentedUserPrice
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export default function NewProductCard({
  image,
  origin,
  variant,
  position,
  gridType,
  userPrice,
  onClick,
}: Props) {
  const {sendClickEvent} = useEvents()
  const {sendEcommerceClickEvent} = useTagmanager()
  const {handleCopyText} = useCommonContext()

  const price =
    userPrice?.price?.price ||
    variant?.listings?.[settings.channel]?.price?.price ||
    0
  const lowestPrice =
    userPrice?.lowestPrice ||
    variant?.listings?.[settings.channel]?.price?.lowestPrice ||
    0
  const discountedPrice =
    userPrice?.userPrice ||
    variant?.listings?.[settings.channel]?.price?.discountedPrice ||
    0

  const sellingPoints = variant?.product?.sellingPoints || []
  const horizontal = gridType === 'list'
  const small = gridType === 'small'

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      !!onClick && onClick(e)
      // TODO: Combine events for google analytics and algolia
      sendEcommerceClickEvent(variant, position, origin)

      sendClickEvent(
        'clickedObjectIDsAfterSearch',
        'Product Clicked After Search',
        variant.sku,
        position,
      )
    },
    [
      origin,
      variant,
      position,
      onClick,
      sendClickEvent,
      sendEcommerceClickEvent,
    ],
  )

  if (variant === undefined)
    return <ProductCardLoader horizontal={horizontal} />

  return (
    <CardWrapper style={{flexDirection: horizontal ? 'row' : 'column'}}>
      <ProductTags variant={variant} />

      <ProductLink
        href={`/vorur/${variant.product.slug}/${variant.sku}`}
        onClick={handleClick}
      >
        <Description style={{flexDirection: horizontal ? 'row' : 'column'}}>
          <Relative>
            <ProductImage src={image.url} alt={image.alt} />
            <ItemBanners variant={variant} small={small} />
          </Relative>

          <ProductText>
            <H5>{variant.name || ''}</H5>
            <Sku bold onClick={(e) => handleCopyText(e, variant.sku)}>
              {variant.sku}
            </Sku>

            {!small && (
              <SellingPoint
                horizontal={horizontal}
                sellingPoints={sellingPoints}
              />
            )}
          </ProductText>
        </Description>
      </ProductLink>

      <FunctionWrapper className={classNames({horizontal})}>
        <PriceWrapper className={classNames({horizontal})}>
          <ProductPrices
            price={price}
            lowestPrice={lowestPrice}
            discountedPrice={discountedPrice}
          />
        </PriceWrapper>

        {horizontal && (
          <AddToCartButton
            variant={variant}
            buttonText="Bæta í körfu"
            newDesign
            fullWidth
          />
        )}

        <ActionButtons className={classNames({horizontal})}>
          <ProductActionButtons variant={variant} horizontal={horizontal} />
        </ActionButtons>
      </FunctionWrapper>
    </CardWrapper>
  )
}
