import {palette} from 'styled-tools'
import styled from 'styled-components'
import classNames from 'classnames'

import {styledTheme} from '@festi/common/themes'
import {
  VariantList,
  VariantDetail,
  Variant,
  ProductDetail,
} from '@festi/common/api/rest'
import {H6} from '@festi/common/components/typography'
import {onDutyFree} from '@festi/common/constants/channels'
import {isNewProduct} from '@festi/common/utils/products'
import {fluidRange} from '@festi/common/utils/styles'

/*
const testTags: Tag[] = [
  {title: 'Nýtt', color: 'lightBlue'},
  {title: `${toInteger(0.1 * 100)}%`, color: 'pink'},
  {title: 'Uppselt', color: 'ui10Solid'},
]
 */

const ProductTagsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 4px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  pointer-events: none;

  &.isProductPage {
    border-radius: 0;
    z-index: 2;
    padding-left: 6px;
  }
`

interface TagProps {
  backgroundColor: string
}

const Tags = styled.div<TagProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 12px;
  text-align: center;
  background-color: ${(p) => p.backgroundColor || palette('ui10Solid')};
  pointer-events: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transform: skew(-7deg);

  &.isProductPage {
    ${fluidRange('height', '24px', '36px')}
  }
`

const TagText = styled(H6)`
  transform: skew(7deg);

  &.blackTag {
    color: ${palette('yellow')};
  }
`

interface Tag {
  title: string
  color: string
}

interface ProductTagsProps {
  variant: VariantList | VariantDetail | Variant
  product?: ProductDetail
  isProductPage?: boolean
}

export default function ProductTags({
  variant,
  product,
  isProductPage = false,
}: ProductTagsProps): JSX.Element {
  const {
    outlet,
    isInStockWeb,
    isInStockDutyfree,
    recommended,
    inventoryStatus,
  } = variant
  const {online} = 'product' in variant ? variant?.product : product

  const isNew = isNewProduct(online)
  const outOfStock = !(onDutyFree
    ? isInStockDutyfree
    : Object.values(inventoryStatus).some(
        (status) => status === 'in_stock' || status === 'low',
      ))
  const outOfStockWeb = !onDutyFree && !outOfStock && !isInStockWeb

  const tags: Tag[] = [
    isNew && {title: 'Nýtt', color: 'lightBlue'},
    outOfStock && {title: 'Uppselt', color: 'ui10Solid'},
    outOfStockWeb && {title: 'Uppselt á vef', color: 'ui10Solid'},
    recommended && {title: 'ELKO mælir með', color: 'green'},
    outlet && !onDutyFree && {title: 'Tækifæri', color: 'black'},
  ]

  return (
    <ProductTagsWrapper className={classNames({isProductPage})}>
      {tags?.map(
        (tag, i) =>
          !!tag && (
            <Tags
              key={i}
              backgroundColor={styledTheme.palette[tag.color]}
              className={classNames({isProductPage})}
            >
              <TagText
                className={classNames({blackTag: tag.color === 'black'})}
                headline
              >
                {tag.title}
              </TagText>
            </Tags>
          ),
      )}
    </ProductTagsWrapper>
  )
}
