import {useCallback} from 'react'

import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import Message2LineIcon from 'remixicon-react/Message2LineIcon'

import {BambuserModal} from '@festi/common/components/videocall'
import {useBambuser, useComparison} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

const BubbleWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: ${styledTheme.zIndex.chatBubble};
  transition: bottom 0.2s ease-out;

  &.isComparing {
    bottom: 136px;
  }

  &.scrollRowOpen {
    ${fluidRange('bottom', '88px', '100px')}
  }

  &.isComparing.scrollRowOpen {
    ${fluidRange('bottom', '210px', '222px')}
  }
`

const ImageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${fluidRange('width', '64px', '80px', 'xs', 'lg')}
  ${fluidRange('height', '64px', '80px', 'xs', 'lg')}
  background-color: ${palette('green')};
  border-radius: 50%;
  cursor: pointer;

  .remixicon-icon {
    width: 32px;
    height: 32px;
    fill: ${palette('white')};
  }
`

interface Props {
  isOpen: boolean
  disableVideo?: boolean
  loadBambuser?: boolean
  setIsOpen: (open: boolean) => void
  sendTagManager: (event: string, label: string) => void
  handleLoadBambuser: () => void
}

export default function ChatBubble({
  isOpen,
  disableVideo,
  setIsOpen,
  sendTagManager,
  handleLoadBambuser,
}: Props) {
  const {isComparing} = useComparison()
  const {changeChatBubblePosition} = useBambuser()

  const onClickChat = useCallback(() => {
    sendTagManager('open chat', 'chat')
    window.zE.activate()
  }, [sendTagManager])

  return (
    <>
      <BubbleWrapper
        className={cn({
          isComparing,
          scrollRowOpen: changeChatBubblePosition,
        })}
      >
        <ImageIcon
          onClick={() => {
            handleLoadBambuser()

            if (disableVideo) {
              onClickChat()
            } else {
              setIsOpen(!isOpen)
              sendTagManager(isOpen ? 'close main' : 'open main', 'main bubble')
            }
          }}
          className={cn({isOpen})}
        >
          <Message2LineIcon />
        </ImageIcon>
      </BubbleWrapper>

      <BambuserModal
        isOpen={isOpen}
        disableVideo={disableVideo}
        sendTagManager={sendTagManager}
        onRequestClose={() => setIsOpen(false)}
      />
    </>
  )
}
