import React, {useCallback, useRef, useState} from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'
import classNames from 'classnames'
import {useClickAway} from 'react-use'

import {MainButton} from '../../buttons'
import {styledTheme} from '@festi/common/themes'

const Wrapper = styled.div`
  position: relative;

  &.displayNone {
    display: none;
  }
`

const Portal = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  min-width: 320px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  max-width: 200px;
  z-index: 10;
  transition: all 0.2s linear;

  &.open {
    height: auto;
    opacity: 1;
    overflow: visible;
  }
`

const Content = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${palette('border')};
  background-color: ${palette('white')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

interface Props {
  label: string | React.ReactNode
  content: React.ReactNode
  displayNone?: boolean
  active?: boolean
}

export default function CustomDropdown({
  label,
  content,
  displayNone,
  active = false,
}: Props) {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  useClickAway(ref, () => {
    setOpen(false)
  })

  return (
    <Wrapper ref={ref} className={classNames({displayNone})}>
      <MainButton
        label={label}
        style={{
          border: `1px solid ${
            open || active ? styledTheme.palette.ui50Solid : 'transparent'
          }`,
        }}
        buttonVariant="negative"
        onClick={handleOpen}
      />

      <Portal className={classNames({open})}>
        <Content>{content}</Content>
      </Portal>
    </Wrapper>
  )
}
