import {
  isLink,
  isList,
  isListItem,
  isHeading,
  isParagraph,
} from 'datocms-structured-text-utils'
import Link from 'next/link'
import {StructuredText, renderRule} from 'react-datocms'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'

import {H1, H2, H3, H4, H5, H6, P} from '@festi/common/components/typography'

import {styledTheme} from '../../themes'

interface StructuredTextContent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}
interface Props {
  content: StructuredTextContent
  darkMode?: boolean
}

const LI = styled.li`
  ${P} {
    margin-bottom: 0;
  }
`

const UL = styled.ul`
  margin-bottom: 32px;

  ${LI} {
    display: flex;

    &::before {
      content: '-';
      color: ${palette('green')};
      margin-right: 10px;
    }
  }

  ${P} {
    margin-bottom: 0;
  }
`

const OL = styled.ul`
  margin-bottom: 32px;

  ${LI} {
    list-style-type: decimal;
    margin-left: 15px;
    color: ${prop('color')};
  }

  ${P} {
    margin-bottom: 0;
  }
`

const renderRules = (darkMode: boolean) => [
  renderRule(isLink, ({node, children, key}) => {
    return (
      <Link
        key={key}
        href={node.url}
        style={{cursor: styledTheme.cursor.pointer}}
      >
        {children}
      </Link>
    )
  }),
  renderRule(isHeading, ({node, children, key}) => {
    const Heading = [H1, H2, H3, H4, H5, H6][node.level - 1]
    return (
      <Heading key={key} withGutter>
        {children}
      </Heading>
    )
  }),
  renderRule(isParagraph, ({children, key}) => {
    return (
      <P key={key} darkMode={darkMode} withGutter>
        {children}
      </P>
    )
  }),
  renderRule(isList, ({node, children, key}) => {
    const List = node.style === 'bulleted' ? UL : OL
    return (
      <List
        key={key}
        color={darkMode ? styledTheme.palette.white : styledTheme.palette.blue}
      >
        {children}
      </List>
    )
  }),
  renderRule(isListItem, ({children, key}) => {
    return <LI key={key}>{children}</LI>
  }),
]

export default function DatoStructuredText({
  darkMode = false,
  content,
}: Props): JSX.Element {
  return <StructuredText data={content} customRules={renderRules(darkMode)} />
}
