import {withFormik, FormikProps} from 'formik'
import {toast} from 'react-toastify'
import {Col, media, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {Address, CreateAddressRequest, restApi} from '@festi/common/api/rest'
import {useAuth} from '@festi/common/contexts'
import {MainButton, OutlinedButton} from '@festi/common/components/buttons'
import {ExpandableFormWrapper} from '@festi/common/components/common'
import {addressValuesToAddressRequest} from '@festi/common/utils/checkout'
import settings from '@festi/common/constants/settings'

import AddressFormFields, {
  AddressFormValues,
  validateAddressFormFields,
} from './AddressFormFields'

const newDesign = settings.redesign

interface Error {
  [key: string]: string[]
}

const FormContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`

const Spacer = styled.div`
  height: 24px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-inline: 8px;

  ${media.md`
    width: 92px;
  `}
`

interface MyFormProps {
  ssn?: string
  handleCreateAddress: (
    address: CreateAddressRequest,
  ) => Promise<void | Address[]>
}

const Form = ({
  values,
  isSubmitting,
  handleSubmit,
  setFieldValue,
}: FormikProps<AddressFormValues> & MyFormProps): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      <AddressFormFields
        ssn={values?.ssn}
        handleNameChange={(type, value) => setFieldValue(type, value)}
      />
      <Spacer />
      <Row justifyContent={newDesign ? 'end' : 'center'}>
        {newDesign ? (
          <ButtonWrapper>
            <MainButton
              label="Vista"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            />
          </ButtonWrapper>
        ) : (
          <Col col={12} md={6} lg={4}>
            <OutlinedButton type="submit" disabled={isSubmitting}>
              Vista
            </OutlinedButton>
          </Col>
        )}
      </Row>
    </form>
  )
}

const AddressFormik = withFormik<MyFormProps, AddressFormValues>({
  mapPropsToValues: ({ssn}) => ({
    ssn: ssn || '',
    name: '',
    email: '',
    company: '',
    address: '',
    apartment: '',
    country: {
      code: '',
      name: '',
    },
    city: '',
    phoneNumber: '',
  }),

  validate: (values) => {
    return validateAddressFormFields(values, false)
  },

  handleSubmit: async (values, {props: {handleCreateAddress}, resetForm}) => {
    const addr = addressValuesToAddressRequest(values)

    return handleCreateAddress(addr).then(() => resetForm())
  },

  displayName: 'AddressForm',
})(Form)

interface Props {
  label: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleAddAddress?: (address: Address) => void
}

export default function AddressForm({
  label,
  isOpen,
  setIsOpen,
  handleAddAddress,
}: Props): JSX.Element {
  const {user, addresses, mutateAllAddresses} = useAuth()

  const handleCreateAddress = async (address: CreateAddressRequest) => {
    return await restApi
      .usersMeAddressesCreate(address)
      .then((res) => {
        const data = res?.data

        setIsOpen(false)

        toast.success('Heimilisfang vistað')

        mutateAllAddresses(data)

        const address = data.find((r) => !addresses?.some((a) => a.id === r.id))

        !!(handleAddAddress && address) && handleAddAddress(address)

        return data
      })
      .catch((res) => {
        const error: Error = res?.error

        const errorMsg = Object.entries(error)
          .map(([key, value]) => `${key}: ${value.join(', ')}`)
          .join('. ')

        toast.error(errorMsg || 'Ekki tókst að vista heimilisfang')

        if (!errorMsg) {
          throw error
        }
      })
  }

  return (
    <ExpandableFormWrapper
      label={label}
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <FormContainer>
        <AddressFormik
          ssn={user?.ssn}
          handleCreateAddress={handleCreateAddress}
        />
      </FormContainer>
    </ExpandableFormWrapper>
  )
}
