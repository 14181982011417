import {media} from 'styled-bootstrap-grid'
import {css} from 'styled-components'
import {ifProp, palette, theme} from 'styled-tools'

import settings from '@festi/common/constants/settings'
import {styledTheme} from '@festi/common/themes'

const newDesign = settings.redesign

interface BtnProps {
  accentColor: string
  darkMode?: boolean
}

interface OutlineBtnProps {
  borderColor?: string
}

interface IconBtnProps {
  accentColor?: string
  large?: boolean
  backgroundColor?: string
  hoverBackgroundColor?: string
  borderRadius?: boolean
}

export function buttonStyles({accentColor}: BtnProps) {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    color: ${ifProp({darkMode: true}, palette('blue'), palette('white'))};
    height: 56px;
    min-height: 56px;
    border-radius: 0;
    padding: 0 18px;
    box-sizing: border-box;
    border: 0;
    border-left: ${ifProp({withBar: true}, '4px', '0')} solid
      ${palette(accentColor)};
    width: 100%;
    transition: color 0.15s, border-color 0.15s, background-color 0.15s;
    background-color: ${ifProp(
      {darkMode: true},
      palette('white'),
      palette('blue'),
    )};

    .remixicon-icon {
      transition: transform 0.15s ease-out, fill 0.15s;
      fill: ${palette(accentColor)};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: ${theme('boxShadow.button')};
      opacity: 0;
      transition: opacity 0.15s;
    }

    &:focus,
    &:hover&:not(:disabled) {
      outline: none;

      &::before {
        opacity: 1;
      }
    }

    &:hover&:not(:disabled) {
      cursor: pointer;
      color: ${palette('blue')};
      background-color: ${palette(accentColor)};
      border-color: ${palette('blue')};

      .remixicon-icon {
        fill: ${palette('blue')};
      }
    }

    &:active {
      .remixicon-icon {
        transform: scale(0.8);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${media.sm`
      padding: 0 24px;
    `}
  `
}

export function buttonTextStyles() {
  return css`
    color: inherit;
    width: 100%;
    text-align: center;
    padding-right: ${ifProp({withIcon: true}, '24px', '0')};
    font-size: 1rem;
    font-weight: 500;
  `
}

export function outlinedButtonStyles({borderColor}: OutlineBtnProps) {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    color: ${palette('blue')};
    height: 56px;
    min-height: 56px;
    border-radius: 0;
    border: 2px solid
      ${borderColor ? palette(borderColor) : palette('lightBlue')};
    padding: 0 24px;
    box-sizing: border-box;
    background-color: ${palette('white')};
    width: 100%;

    .remixicon-icon {
      transition: fill 0.15s, transform 0.15s ease-out;
      fill: ${palette('blue')} !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: ${theme('boxShadow.button')};
      opacity: 0;
      transition: opacity 0.15s;
    }

    &:focus,
    &:hover&:not(:disabled) {
      outline: none;
      cursor: pointer;

      &::before {
        opacity: 1;
      }
    }

    &:active {
      .remixicon-icon {
        transform: scale(0.8);
      }
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  `
}

export function iconButtonStyles({
  accentColor,
  large,
  backgroundColor,
  hoverBackgroundColor,
  borderRadius,
}: IconBtnProps) {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: ${large ? '56px' : '48px'};
    min-height: ${large ? '56px' : '48px'};
    width: ${large ? '56px' : '48px'};
    border-radius: ${borderRadius ? '50%' : '0'};
    padding: 0;
    border: 0;
    transition: background-color 0.15s;
    background-color: ${palette(backgroundColor || 'blue')};

    .remixicon-icon {
      transition: transform 0.15s ease-out, fill 0.15s;
      fill: ${palette(accentColor || 'green')};
    }

    &:hover&:not(:disabled) {
      outline: none;
      cursor: pointer;
      background-color: ${palette(
        hoverBackgroundColor || accentColor || 'green',
      )};

      .remixicon-icon {
        fill: ${palette('blue')};
      }
    }

    &:active {
      .remixicon-icon {
        transform: scale(0.8);
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      color: ${palette('white')};
    }
  `
}

export function actionButtonStyles() {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: ${ifProp({center: true}, 'center', 'flex-start')};
    height: 56px;
    min-height: 56px;
    border-radius: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 8px;
    border: 0;
    width: 100%;
    background-color: transparent;
    cursor: ${styledTheme.cursor.pointer};
    color: ${palette('blue')};

    .remixicon-icon {
      display: block;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      transition: transform 0.15s ease-out, fill 0.15s;
      fill: ${palette(newDesign ? 'blue' : 'lightBlue')};
    }

    &:focus,
    &:hover&:not(:disabled) {
      outline: none;

      .remixicon-icon {
        transform: translateX(5px);
      }
    }

    &:active {
      .remixicon-icon {
        transform: scale(0.8);
      }
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  `
}

export function actionLabelStyles() {
  return css`
    color: palette('blue');
    font-weight: 500;
    text-align: center;
  `
}

export function checkButtonStyles() {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    color: ${palette('blue')};
    height: 56px;
    min-height: 56px;
    border-radius: 0;
    border: 2px solid ${palette('border')};
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 0;
    box-sizing: border-box;
    background-color: ${palette('white')};
    width: 100%;
    transition: opacity 0.15s;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: ${theme('boxShadow.button')};
      opacity: 0;
      transition: opacity 0.15s;
    }

    &:focus,
    &:hover&:not(:disabled) {
      outline: none;
      cursor: pointer;

      &::before {
        opacity: 1;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      color: inherit;
    }
  `
}
