import React from 'react'

import Link from 'next/link'
import {Image} from 'react-datocms'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'

import {
  ImageCardListFields,
  ImageCardListFields_imageCards as ImageCardType,
} from '@festi/common/api/datocms/types/ImageCardListFields'
import {H1} from '@festi/common/components/typography'
import settings from '@festi/common/constants/settings'

import {BlockWrapper} from '../layout'

const newDesign = settings.redesign

const ImageCardWrapper = styled.div`
  height: 100%;
  padding-bottom: 20px;
`

const ImageCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${palette('white')};
  max-width: 390px;
  margin: auto;
  border: 1px solid ${palette('ui20Solid')};
  transition: border-color 0.15s;
  border-radius: ${newDesign ? '12px' : 'unset'};
  overflow: hidden;

  &:hover {
    box-shadow: ${theme('boxShadow.card')};
    border-color: ${palette('ui60')};
  }
`

const ImageWrapper = styled.div`
  display: flex;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 20px;
`

const MobileImage = styled.div`
  ${media.sm`
    display: none;
  `}
`

const DesktopImage = styled.div`
  display: none;

  ${media.sm`
    display: block;
  `}
`

export default function ImageCardList({
  imageCards,
  darkMode,
}: ImageCardListFields): JSX.Element {
  return (
    <BlockWrapper darkMode={darkMode}>
      <Container>
        <Row>
          {imageCards?.map(({id, title, image, path}: ImageCardType) => (
            <Col md={3} sm={6} xs={12} key={id}>
              <Link href={path}>
                <ImageCardWrapper>
                  <ImageCard>
                    <TitleWrapper>
                      <H1 size={5}>{title}</H1>
                    </TitleWrapper>
                    <ImageWrapper>
                      <MobileImage>
                        <Image data={image.mobileImg} />
                      </MobileImage>
                      <DesktopImage>
                        <Image data={image.desktopImg} />
                      </DesktopImage>
                    </ImageWrapper>
                  </ImageCard>
                </ImageCardWrapper>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </BlockWrapper>
  )
}
