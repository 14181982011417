import classNames from 'classnames'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {styledTheme} from '@festi/common/themes'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  icon: RemixiconReactIconComponentType
  iconSize?: number
  small?: boolean
}

const Btn = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 22px;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${styledTheme.palette.link};
  cursor: ${styledTheme.cursor.pointer};

  &.small {
    font-size: 14px;
    gap: 4px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid ${styledTheme.palette.lightBlue};
    outline-offset: 2px;
    border-radius: 4px;
  }
`

const BtnText = styled.span`
  position: relative;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 1px;
`

export default function NewActionButton({
  children,
  icon: Icon,
  iconSize = 14,
  small = false,
  ...props
}: Props): JSX.Element {
  return (
    <Btn className={classNames({small})} {...props}>
      <Icon size={iconSize} />
      <BtnText>{children}</BtnText>
    </Btn>
  )
}
