import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'

import {
  Page_page_content_IconCardImageBlockRecord_content as IconCardContent,
  Page_page_content_CardImageBlockRecord_content as CardContent,
} from '@festi/common/api/datocms/types/Page'
import {DatoStructuredText, DynamicIcon} from '@festi/common/components/common'
import {H3} from '@festi/common/components/typography'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

const newDesign = settings.redesign

interface TextCardProps {
  title: string
  content: IconCardContent | CardContent
  icon?: string
}

const TextCardContainer = styled.div`
  z-index: 1;
  background-color: ${palette('white')};
  border: 1px solid ${palette('ui20')};
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: ${newDesign ? '12px' : 'unset'};
`

const TextCardInner = styled.div`
  ${fluidRange('margin', '30px', '60px')};
`

const IconWrapper = styled.div`
  margin-bottom: 30px;
  max-height: 30px;
  max-width: 30px;
`

export default function TextCard({
  title,
  content,
  icon,
}: TextCardProps): JSX.Element {
  return (
    <TextCardContainer>
      <TextCardInner>
        {!!icon && (
          <IconWrapper>
            <DynamicIcon size={36} icon={icon} />
          </IconWrapper>
        )}
        <H3 withGutter>{title}</H3>
        <DatoStructuredText content={content} />
      </TextCardInner>
    </TextCardContainer>
  )
}
