import React, {useCallback, useState} from 'react'

import {palette} from 'styled-tools'
import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'

import Expandable from '../Expandable'

const Root = styled(Popover.Root)``

const Trigger = styled(Popover.Trigger)`
  border-radius: 8px;

  &[data-state='open'] {
    background-color: ${palette('ui10Solid')};
  }
`

const Portal = styled(Popover.Portal)``

const Content = styled(Popover.Content)`
  position: relative;
  z-index: 1000;
  background: ${palette('white')};
  border: 1px solid ${palette('border')};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

interface Props {
  trigger: React.ReactNode
  content: React.ReactNode
}

export default function CustomPopover({trigger, content}: Props) {
  const [open, setOpen] = useState(false)
  const [mounted, setMounted] = useState<true | undefined>(undefined)

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open)
    setMounted(true)

    if (!open) {
      setTimeout(() => setMounted(undefined), 200)
    }
  }, [])

  return (
    <Root onOpenChange={handleOpenChange}>
      <Trigger asChild>
        <div>{trigger}</div>
      </Trigger>

      <Portal forceMount={mounted}>
        <Content
          align="end"
          sideOffset={4}
          collisionPadding={16}
          forceMount={mounted}
        >
          <Expandable key={'custom-popover'} expanded={open}>
            {content}
          </Expandable>
        </Content>
      </Portal>
    </Root>
  )
}
