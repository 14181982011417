import React from 'react'

import {useField, FieldConfig} from 'formik'
import cn from 'classnames'
import Link from 'next/link'
import styled from 'styled-components'
import {palette, prop} from 'styled-tools'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'

import {P} from '@festi/common/components/typography'

import {styledTheme} from '../../themes'
import {InfoTooltip} from '../common'
import ErrorMsg from './ErrorMsg'

export type Highlight = {
  text: string
  error?: boolean
}

interface AdornmentProps {
  color: string
}

export const FocusLine = styled.div`
  display: block;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${palette('lightBlue')};
  width: 0;
  z-index: 1;
  transition: width 0.15s ease-out;
`

export const Placeholder = styled.span`
  color: ${palette('ui50Solid')};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  left: 16px;
  top: 20px;
  pointer-events: none;
  transform-origin: 0 50%;
  transition: transform 0.15s ease-out, color 0.15s;
`

export const Adornment = styled.div<AdornmentProps>`
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: ${prop('color')};
  border-radius: 9px;
  top: calc(50% - 9px);
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.15s;
`

export const InputField = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  z-index: 0;
  padding: 14px 42px 0 16px;
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  border-bottom: 1px solid ${palette('ui20Solid')};
  caret-color: ${palette('blue')};
  background-color: ${palette('white')};
  transition: opacity 0.15s;

  &:disabled {
    opacity: 0.5;
  }
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  margin-bottom: 4px;

  input {
    &:focus {
      outline: none;

      & ~ ${Placeholder} {
        transform: scale(0.75) translateY(-18px);
        font-weight: 500;
        color: ${palette('lightBlue')};
      }

      & ~ ${FocusLine} {
        width: 100%;
      }
    }

    &:not(:placeholder-shown) {
      & ~ ${Placeholder} {
        transform: scale(0.75) translateY(-18px);
        font-weight: 500;
        color: ${palette('lightBlue')};
      }
    }

    &::placeholder {
      opacity: 0;
    }
  }
`

const InputWrapper = styled.div`
  padding-bottom: 12px;
`

const InfoWrapper = styled.div`
  position: absolute;
  top: calc(50% - 9px);
  right: 16px;
  z-index: 1;

  a {
    color: ${palette('lightBlue')};
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 1px;
  }
`

const Highlight = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.2;
  padding: 5px 0 5px 10px;
  color: ${palette('lightBlue')};

  &.error {
    color: ${palette('error')};
    font-weight: 400;
  }
`

interface InputSleaveProps {
  placeholder?: string
  showValidBadge?: boolean
  errorMsg?: string
  info?: string
  link?: string
  linkLabel?: string
  highlight?: Highlight
  children: React.ReactNode
}

export function InputSleave({
  placeholder,
  showValidBadge,
  errorMsg,
  info,
  link,
  linkLabel,
  highlight,
  children,
}: InputSleaveProps): JSX.Element {
  return (
    <InputWrapper>
      <InputContainer>
        {/* Placeholder prop needed for ':not(:placeholder-shown)' to work above */}
        {children}
        <Placeholder>{placeholder}</Placeholder>
        <FocusLine />
        {!errorMsg && !!info && (
          <InfoWrapper>
            <InfoTooltip id="input-info">
              <P darkMode>{info}</P>
              {!!link && <Link href={link}>{linkLabel}</Link>}
            </InfoTooltip>
          </InfoWrapper>
        )}
        <Adornment
          color={styledTheme.palette.lightBlue}
          style={{opacity: showValidBadge ? 1 : 0}}
        >
          <CheckLineIcon size={16} color={styledTheme.palette.white} />
        </Adornment>
        <Adornment
          color={styledTheme.palette.transparent}
          style={{opacity: errorMsg ? 1 : 0}}
        >
          <ErrorWarningFillIcon size={18} color={styledTheme.palette.error} />
        </Adornment>
      </InputContainer>
      {errorMsg ? (
        <ErrorMsg>{errorMsg}</ErrorMsg>
      ) : (
        highlight && (
          <Highlight className={cn({error: highlight.error})}>
            {highlight.text}
          </Highlight>
        )
      )}
    </InputWrapper>
  )
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  showValidBadge?: boolean
  errorMsg?: string
  info?: string
  link?: string
  linkLabel?: string
  highlight?: Highlight
  characterLimit?: number
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}

export default function Input({
  showValidBadge,
  errorMsg,
  placeholder,
  characterLimit,
  highlight,
  ...props
}: InputProps): JSX.Element {
  return (
    <InputSleave
      placeholder={placeholder || ''}
      showValidBadge={showValidBadge}
      errorMsg={errorMsg}
      highlight={highlight}
    >
      <InputField
        {...props}
        placeholder={placeholder}
        style={{
          borderColor: errorMsg
            ? styledTheme.palette.error
            : styledTheme.palette.ui20Solid,
        }}
        maxLength={characterLimit}
      />
    </InputSleave>
  )
}

export function FormikInput(
  props: InputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldConfig<any>,
): JSX.Element {
  const [field, meta] = useField(props)
  const errorMsg = !!meta.touched && meta.error

  return <Input {...field} {...props} errorMsg={errorMsg || ''} />
}
