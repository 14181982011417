import cn from 'classnames'
import {useCallback} from 'react'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {mainButtonCommonStyles} from './buttonCommonStyles'

interface MainIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonVariant?:
    | 'primary'
    | 'secondary'
    | 'negative'
    | 'ghost'
    | 'ghostNegative'
  size?: 'small' | 'medium'
  loading?: boolean
  icon: RemixiconReactIconComponentType
  disabled?: boolean
  iconColor?: string
  round?: boolean
}

const Btn = styled.button`
  &.medium {
    width: 46px;
    height: 46px;
  }
  &.small {
    width: 34px;
    height: 34px;
  }
  &.round {
    border-radius: 50%;
  }
  ${mainButtonCommonStyles()}
`
export default function MainIconButton({
  buttonVariant = 'primary',
  size = 'medium',
  loading = false,
  icon: Icon,
  disabled = false,
  iconColor = 'currentColor',
  round = false,
  ...props
}: MainIconButtonProps) {
  const variantStyles = {
    primary: buttonVariant === 'primary',
    secondary: buttonVariant === 'secondary',
    negative: buttonVariant === 'negative',
    ghost: buttonVariant === 'ghost',
    ghostNegative: buttonVariant === 'ghostNegative',
  }

  const sizeStyles = {
    small: size === 'small',
    medium: size === 'medium',
  }

  const getIconSize = useCallback(() => {
    if (size === 'small') {
      return 16
    }
    return 24
  }, [size])

  return (
    <Btn
      disabled={disabled || loading}
      className={cn(variantStyles, sizeStyles, {loading, round})}
      {...props}
    >
      <Icon size={getIconSize()} color={iconColor} />
    </Btn>
  )
}
