import styled from 'styled-components'
import {styledTheme} from '@festi/common/themes'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'

const Btn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: ${styledTheme.cursor.pointer};
  border: none;
  background-color: transparent;
  padding: 8px;

  &:focus-visible,
  &:hover {
    background-color: ${styledTheme.palette.ui10Solid};
  }

  &:focus {
    outline: none;
  }
`
export default function CloseButton({
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Btn aria-label="Loka" {...props}>
      <CloseLineIcon size={20} color={styledTheme.palette.blue} />
    </Btn>
  )
}
