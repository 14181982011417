import React, {useState} from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {H5} from '@festi/common/components/typography'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

import {IconBox} from '../common'

const newDesign = settings.redesign

interface Props {
  icon: RemixiconReactIconComponentType
  title: string
  items: string[]
  editForm?: React.ReactNode
  onClickRemove?: () => void
}

const Card = styled.div`
  position: relative;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${palette('ui20Solid')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${palette('white')};
  overflow: hidden;
  transition: border-color 0.15s;
  border-radius: ${newDesign ? '12px' : 'unset'};
`

const IconContainer = styled.div`
  ${fluidRange('height', '48px', '56px')}
  ${fluidRange('width', '48px', '56px')}
  ${fluidRange('margin-right', '24px', '32px')}
  background-color: ${palette('light')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${fluidRange('padding-right', '52px', '78px')}
`

const InfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const EditFormContainer = styled.div`
  /* TODO */
`

const TextItem = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${palette('blue')};
  margin-bottom: 5px;
  line-height: 1.2;

  strong {
    font-weight: 500;
  }
`

const ActionBtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 20px;
  background-color: ${palette('white')};
  z-index: 1;
`

const ActionBtn = styled.button`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: underline;
  color: ${palette('ui50Solid')};
  transition: color 0.15s;
  background: none;
  border: 0;
  cursor: ${styledTheme.cursor.pointer};
  padding: 0 4px;

  &:hover,
  &:focus {
    color: ${palette('ui80Solid')};
    outline: none;
  }
`

const PromptText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${palette('ui60Solid')};
  margin-right: 4px;
`

export const InformationCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  ${media.sm`
    grid-template-columns: 1fr 1fr;
  `}
`

export default function InformationCard({
  icon: Icon,
  title,
  items,
  editForm,
  onClickRemove,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [promptActive, setPromptActive] = useState<boolean>(false)

  return (
    <Card
      style={{
        borderColor: isEditing
          ? styledTheme.palette.lightBlue
          : styledTheme.palette.ui20Solid,
      }}
    >
      {newDesign ? (
        <IconBox icon={Icon} />
      ) : (
        <IconContainer>
          <Icon size={24} color={styledTheme.palette.lightBlue} />
        </IconContainer>
      )}
      <ContentContainer>
        {!isEditing && (
          <InfoContainer>
            {!!title && (
              <TextItem>
                <strong>{title}</strong>
              </TextItem>
            )}
            {items?.map((item: string, i: number) => (
              <TextItem key={`item${i}`}>{item}</TextItem>
            ))}
          </InfoContainer>
        )}
        {!!editForm && isEditing && (
          <>
            <H5 withGutter>Breyta upplýsingum</H5>
            <EditFormContainer>{editForm}</EditFormContainer>
          </>
        )}
      </ContentContainer>
      <ActionBtnsWrapper>
        {editForm ? (
          <ActionBtn onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Loka' : 'Breyta'}
          </ActionBtn>
        ) : (
          !!onClickRemove &&
          (promptActive ? (
            <>
              <PromptText>Ertu viss?</PromptText>
              <ActionBtn onClick={onClickRemove}>Já, Eyða</ActionBtn>
              <ActionBtn onClick={() => setPromptActive(false)}>
                Hætta við
              </ActionBtn>
            </>
          ) : (
            <ActionBtn onClick={() => setPromptActive(true)}>Eyða</ActionBtn>
          ))
        )}
      </ActionBtnsWrapper>
    </Card>
  )
}
