import {gql} from '@apollo/client'

const textBlockFragment = gql`
  fragment TextBlockFields on TextBlockRecord {
    __typename
    id
    slug
    title
    darkMode
    inContainer
    mirrored
    content {
      value
    }
    image {
      responsiveImage(imgixParams: {fit: max, w: 800}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
  }
`

const pageHeaderFragment = gql`
  fragment PageHeaderFields on PageHeaderRecord {
    __typename
    id
    slug
    title
    description
    image {
      responsiveImage(imgixParams: {fit: max, w: 1024}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
  }
`

const pageBannerFragment = gql`
  fragment PageBannerFields on PageBannerRecord {
    __typename
    id
    slug
    link
    image {
      responsiveImage(imgixParams: {fit: max, w: 2560}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
    mobileImage {
      responsiveImage(imgixParams: {fit: max, w: 1640}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
  }
`

const cardImageBlockFragment = gql`
  fragment CardImageBlockFields on CardImageBlockRecord {
    __typename
    id
    slug
    title
    mirrored
    fullWidthImage
    content {
      value
    }
    image {
      fullWidth: responsiveImage(imgixParams: {fit: max, w: 1660}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      regular: responsiveImage(imgixParams: {fit: max, w: 1660}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
  }
`

const iconCardImageBlockFragment = gql`
  fragment IconCardImageBlockFields on IconCardImageBlockRecord {
    __typename
    id
    slug
    title
    mirrored
    icon
    content {
      value
    }
    image {
      responsiveImage(imgixParams: {fit: max, w: 800}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
  }
`

const locationListFragment = gql`
  fragment LocationListFields on LocationListRecord {
    __typename
    id
    slug
    title
    description
    darkMode
    showInSlider
    locations {
      id
      title
      location
      link
      logo {
        responsiveImage(imgixParams: {fit: max, h: 66}) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
        url
      }
    }
  }
`

const accordionListFragment = gql`
  fragment AccordionListFields on AccordionListRecord {
    __typename
    id
    slug
    title
    description
    small
    darkMode
    accordions {
      id
      title
      content {
        value
      }
    }
  }
`

const bulletListFragment = gql`
  fragment BulletListFields on BulletListRecord {
    __typename
    slug
    id
    title
    darkMode
    mirrored
    image {
      responsiveImage(imgixParams: {fit: max, h: 800}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
    bullets {
      id
      title
      text
      icon
    }
  }
`

const productSliderFragment = gql`
  fragment ProductSliderFields on ProductSliderRecord {
    __typename
    id
    slug
    title
    description
    darkMode
    hideIfOutOfStock
    products {
      slug
      sku
    }
  }
`

const productListFragment = gql`
  fragment ProductListFields on ProductListRecord {
    __typename
    id
    slug
    title
    description
    darkMode
    hideIfOutOfStock
    products {
      slug
      sku
    }
  }
`

const infoCardListFragment = gql`
  fragment InfoCardListFields on InfoCardListRecord {
    __typename
    id
    slug
    title
    description
    darkMode
    small
    border
    slider
    infoCards {
      id
      title
      icon
      description
      link
    }
  }
`

const infoBulletListFragment = gql`
  fragment InfoBulletListFields on InfoBulletListRecord {
    __typename
    id
    infoBullets {
      id
      title
      icon
      description
      link
    }
  }
`

const contentBlockFragment = gql`
  fragment ContentBlockFields on ContentBlockRecord {
    __typename
    id
    content {
      value
    }
    externalContent {
      content {
        value
      }
    }
  }
`

const imageBlockFragment = gql`
  fragment ImageBlockFields on ImageBlockRecord {
    __typename
    id
    slug
    title
    description
    link
    image {
      responsiveImage(imgixParams: {fit: max, w: 1660, h: 1024}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
  }
`

const videoBlockFragment = gql`
  fragment VideoBlockFields on VideoBlockRecord {
    __typename
    id
    video {
      title
      provider
      providerUid
    }
  }
`

const htmlBlockFragment = gql`
  fragment HtmlBlockFields on HtmlBlockRecord {
    __typename
    id
    slug
    html
  }
`

const videoBannerBlockFragment = gql`
  fragment VideoBannerBlockFields on VideoBannerBlockRecord {
    __typename
    id
    slug
    video {
      video {
        duration
        framerate
        mp4Url
        muxAssetId
        muxPlaybackId
        streamingUrl
        thumbnailUrl
      }
    }
  }
`

const categorySliderFragment = gql`
  fragment CategorySliderFields on CategorySliderRecord {
    __typename
    id
    slug
    title
    description
    darkMode
    categories {
      id
      slug
      image {
        responsiveImage(imgixParams: {fit: max, w: 300}) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`

const imageCardListFragment = gql`
  fragment ImageCardListFields on ImageCardListRecord {
    __typename
    id
    darkMode
    imageCards {
      id
      title
      path
      image {
        mobileImg: responsiveImage(
          imgixParams: {fit: crop, crop: focalpoint, w: 390, h: 150}
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
        desktopImg: responsiveImage(
          imgixParams: {fit: crop, crop: focalpoint, w: 390, h: 270}
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`

const bambuserCtaFragment = gql`
  fragment BambuserCtaFields on BambuserCtaRecord {
    __typename
    id
    title
    description
    loginLabel
    videoCallLabel
    slug
  }
`

const employeeListFragment = gql`
  fragment EmployeeListFields on EmployeeListRecord {
    __typename
    id
    slug
    title
    description
    largeCards
    employees {
      id
      name
      title
      email
      image {
        mobileImg: responsiveImage(
          imgixParams: {fit: crop, crop: focalpoint, w: 98, h: 130}
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
        desktopImg: responsiveImage(
          imgixParams: {fit: crop, crop: focalpoint, w: 256, h: 220}
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`

const gridSystemFragment = gql`
  fragment GridSystemFields on GridSystemRecord {
    __typename
    id
    heading
    gridSelect
    card {
      ... on AdviceCardRecord {
        id
        tag
        title
        image {
          id
          responsiveImage(
            imgixParams: {fit: crop, crop: focalpoint, w: 256, h: 220}
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on BlogCardRecord {
        id
        link
        title
        description
        image {
          id
          responsiveImage(
            imgixParams: {fit: crop, crop: focalpoint, w: 256, h: 220}
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on CategoryRecord {
        id
        slug
        image {
          responsiveImage(imgixParams: {fit: max, w: 300}) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on BrandCardRecord {
        id
        logo {
          id
          responsiveImage {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on ImageCardRecord {
        id
        title
        path
        subtitle
        textboxPosition
        image {
          id
          responsiveImage(
            imgixParams: {fit: crop, crop: focalpoint, w: 256, h: 220}
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on PromoCardRecord {
        id
        title
        path
        subtitle
        textboxColor
        titleColor
        highlightedWordColor
        image {
          id
          responsiveImage(
            imgixParams: {fit: crop, crop: focalpoint, w: 256, h: 220}
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
      ... on InfoCardRecord {
        id
        link
        title
        icon
        description
        __typename
      }
      ... on ProductRecord {
        id
        sku
        slug
        __typename
      }
      ... on LargeImageCardRecord {
        id
        title
        subtitle
        link
        image {
          id
          responsiveImage(imgixParams: {fit: max, w: 885, h: 500}) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
        __typename
      }
    }
  }
`

const carouselBlockFragment = gql`
  fragment CarouselBlockFields on CarouselBlockRecord {
    __typename
    id
    title
    image {
      responsiveImage(imgixParams: {fit: max, w: 1660, h: 1024}) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
      url
    }
    products {
      ... on ProductRecord {
        id
        sku
        slug
        __typename
      }
    }
  }
`

const categoryCarouselFragment = gql`
  fragment CategoryCarouselFields on CategoryCarouselRecord {
    __typename
    id
    title
    categories {
      ... on CategoryRecord {
        id
        slug
        image {
          responsiveImage(imgixParams: {fit: max, w: 300}) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
      }
    }
  }
`

const wishlistSliderFragment = gql`
  fragment WishlistSliderFields on WishlistSliderRecord {
    __typename
    id
    title
  }
`

const productCarouselFragment = gql`
  fragment ProductCarouselFields on ProductCarouselRecord {
    __typename
    id
    slug
    title
    products {
      ... on ProductRecord {
        id
        sku
        slug
        __typename
      }
    }
  }
`

export const pageFragment = gql`
  ${textBlockFragment}
  ${cardImageBlockFragment}
  ${iconCardImageBlockFragment}
  ${locationListFragment}
  ${accordionListFragment}
  ${bulletListFragment}
  ${productSliderFragment}
  ${infoCardListFragment}
  ${contentBlockFragment}
  ${imageBlockFragment}
  ${videoBlockFragment}
  ${pageHeaderFragment}
  ${pageBannerFragment}
  ${htmlBlockFragment}
  ${infoBulletListFragment}
  ${videoBannerBlockFragment}
  ${categorySliderFragment}
  ${imageCardListFragment}
  ${bambuserCtaFragment}
  ${employeeListFragment}
  ${productListFragment}
  ${gridSystemFragment}
  ${carouselBlockFragment}
  ${categoryCarouselFragment}
  ${wishlistSliderFragment}
  ${productCarouselFragment}

  fragment PageFields on PageRecord {
    id
    heading
    path
    seoIndexing
    seo: _seoMetaTags {
      attributes
      content
      tag
    }
    content {
      ... on TextBlockRecord {
        ...TextBlockFields
      }
      ... on CardImageBlockRecord {
        ...CardImageBlockFields
      }
      ... on IconCardImageBlockRecord {
        ...IconCardImageBlockFields
      }
      ... on LocationListRecord {
        ...LocationListFields
      }
      ... on AccordionListRecord {
        ...AccordionListFields
      }
      ... on BulletListRecord {
        ...BulletListFields
      }
      ... on ProductSliderRecord {
        ...ProductSliderFields
      }
      ... on InfoCardListRecord {
        ...InfoCardListFields
      }
      ... on ContentBlockRecord {
        ...ContentBlockFields
      }
      ... on ImageBlockRecord {
        ...ImageBlockFields
      }
      ... on VideoBlockRecord {
        ...VideoBlockFields
      }
      ... on PageHeaderRecord {
        ...PageHeaderFields
      }
      ... on PageBannerRecord {
        ...PageBannerFields
      }
      ... on HtmlBlockRecord {
        ...HtmlBlockFields
      }
      ... on InfoBulletListRecord {
        ...InfoBulletListFields
      }
      ... on InfoBulletListRecord {
        ...InfoBulletListFields
      }
      ... on VideoBannerBlockRecord {
        ...VideoBannerBlockFields
      }
      ... on CategorySliderRecord {
        ...CategorySliderFields
      }
      ... on ImageCardListRecord {
        ...ImageCardListFields
      }
      ... on BambuserCtaRecord {
        ...BambuserCtaFields
      }
      ... on EmployeeListRecord {
        ...EmployeeListFields
      }
      ... on ProductListRecord {
        ...ProductListFields
      }
      ... on GridSystemRecord {
        ...GridSystemFields
      }
      ... on CarouselBlockRecord {
        ...CarouselBlockFields
      }
      ... on CategoryCarouselRecord {
        ...CategoryCarouselFields
      }
      ... on WishlistSliderRecord {
        ...WishlistSliderFields
      }
      ... on ProductCarouselRecord {
        ...ProductCarouselFields
      }
    }
  }
`

export const frontPageFragment = gql`
  ${textBlockFragment}
  ${cardImageBlockFragment}
  ${iconCardImageBlockFragment}
  ${locationListFragment}
  ${accordionListFragment}
  ${bulletListFragment}
  ${productSliderFragment}
  ${infoCardListFragment}
  ${contentBlockFragment}
  ${imageBlockFragment}
  ${videoBlockFragment}
  ${pageHeaderFragment}
  ${pageBannerFragment}
  ${htmlBlockFragment}
  ${infoBulletListFragment}
  ${videoBannerBlockFragment}
  ${categorySliderFragment}
  ${imageCardListFragment}
  ${bambuserCtaFragment}
  ${productListFragment}
  ${gridSystemFragment}
  ${carouselBlockFragment}
  ${categoryCarouselFragment}
  ${wishlistSliderFragment}
  ${productCarouselFragment}

  fragment FrontPageFields on FrontPageRecord {
    id
    seoIndexing
    seo: _seoMetaTags {
      attributes
      content
      tag
    }
    publishedAt: _publishedAt
    content {
      ... on TextBlockRecord {
        ...TextBlockFields
      }
      ... on CardImageBlockRecord {
        ...CardImageBlockFields
      }
      ... on IconCardImageBlockRecord {
        ...IconCardImageBlockFields
      }
      ... on LocationListRecord {
        ...LocationListFields
      }
      ... on AccordionListRecord {
        ...AccordionListFields
      }
      ... on BulletListRecord {
        ...BulletListFields
      }
      ... on ProductSliderRecord {
        ...ProductSliderFields
      }
      ... on InfoCardListRecord {
        ...InfoCardListFields
      }
      ... on ContentBlockRecord {
        ...ContentBlockFields
      }
      ... on ImageBlockRecord {
        ...ImageBlockFields
      }
      ... on VideoBlockRecord {
        ...VideoBlockFields
      }
      ... on PageHeaderRecord {
        ...PageHeaderFields
      }
      ... on PageBannerRecord {
        ...PageBannerFields
      }
      ... on HtmlBlockRecord {
        ...HtmlBlockFields
      }
      ... on InfoBulletListRecord {
        ...InfoBulletListFields
      }
      ... on InfoBulletListRecord {
        ...InfoBulletListFields
      }
      ... on VideoBannerBlockRecord {
        ...VideoBannerBlockFields
      }
      ... on CategorySliderRecord {
        ...CategorySliderFields
      }
      ... on ImageCardListRecord {
        ...ImageCardListFields
      }
      ... on BambuserCtaRecord {
        ...BambuserCtaFields
      }
      ... on ProductListRecord {
        ...ProductListFields
      }
      ... on GridSystemRecord {
        ...GridSystemFields
      }
      ... on CarouselBlockRecord {
        ...CarouselBlockFields
      }
      ... on CategoryCarouselRecord {
        ...CategoryCarouselFields
      }
      ... on WishlistSliderRecord {
        ...WishlistSliderFields
      }
      ... on ProductCarouselRecord {
        ...ProductCarouselFields
      }
    }
  }
`

export const pageQuery = gql`
  ${pageFragment}
  query Page($path: String) {
    page(filter: {path: {eq: $path}}) {
      ...PageFields
    }
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
    }
  }
`

export const breadCrumbsQuery = gql`
  query BreadCrumbs($path: String!) {
    allPages(filter: {path: {matches: {pattern: $path}}}) {
      id
      path
      heading
    }
  }
`

export const frontPagePreviewQuery = gql`
  ${frontPageFragment}
  query FrontPagePreview($slug: String) {
    frontPage(filter: {slug: {eq: $slug}}) {
      ...FrontPageFields
    }
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
    }
  }
`

export const frontPageQuery = gql`
  ${frontPageFragment}
  query FrontPage {
    frontPage(orderBy: _publishedAt_DESC) {
      ...FrontPageFields
    }
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
    }
  }
`

export const allFrontPagesQuery = gql`
  ${frontPageFragment}
  query AllFrontPages($dutyFree: BooleanType) {
    allFrontPages(
      orderBy: _publishedAt_DESC
      filter: {dutyFree: {eq: $dutyFree}}
    ) {
      ...FrontPageFields
    }
    site: _site {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
    }
  }
`

export const allPageNavigationsQuery = gql`
  query AllPageNavigations {
    allPageNavigations(filter: {parent: {exists: false}}) {
      id
      icon
      heading
      pageLink {
        __typename
        ... on ExternalLinkRecord {
          id
          heading
          path
        }
        ... on PageRecord {
          id
          heading
          path
        }
      }
      children {
        id
        heading
        pageLink {
          __typename
          ... on ExternalLinkRecord {
            id
            heading
            path
          }
          ... on PageRecord {
            id
            heading
            path
          }
        }
      }
    }
  }
`
