import React, {useCallback, useEffect, useState} from 'react'

import cn from 'classnames'
import Link from 'next/link'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {Container, media} from 'styled-bootstrap-grid'
import useEmblaCarousel from 'embla-carousel-react'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {Page_page_content_WishlistSliderRecord} from '@festi/common/api/datocms/types/Page'
import {useWishlist} from '@festi/common/utils/wishlists'
import {useUserPrices} from '@festi/common/utils/rest'
import {fluidRange} from '@festi/common/utils/styles'
import {useAuth} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'

import {BlockWrapper} from 'src/components/layout'
import LargeWishlistCard from 'src/components/cards/redesign/wishlist/LargeWishlistCard'
import ProductCardLoader from 'src/components/cards/redesign/utilities/ProductCardLoader'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const Title = styled.div`
  ${fluidRange('font-size', '20px', '24px')};
  font-weight: 500;
  color: ${palette('blue')};
`

const Count = styled.span`
  ${fluidRange('font-size', '14px', '16px')};
  color: ${palette('blue')};
`

const TextLink = styled(Link)`
  font-size: 16px;
  line-height: 22px;
  padding-right: 0;
  color: ${palette('blue')};
  text-align: right;
  text-decoration: underline;
  cursor: ${styledTheme.cursor.pointer};

  ${media.sm`
    padding-right: 104px;
  `}
`

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Carousel = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const ButtonWrapper = styled.div`
  position: absolute;
  display: none;
  top: -6px;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  z-index: 1;

  ${media.sm`
    display: flex;
  `}
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fluidRange('width', '36px', '36px')};
  ${fluidRange('height', '36px', '36px')};
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('white')};
  cursor: ${styledTheme.cursor.pointer};

  &:hover {
    background-color: ${palette('ui5Solid')};
    border-color: ${palette('ui10Solid')};
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const EmblaContainer = styled.div`
  display: flex;
  width: 100%;
  ${fluidRange('gap', '8px', '10px')};
`

const EmblaSlider = styled.div`
  flex: 0 0 calc(100% / 6 - 9px);
  min-width: 219px;

  @media (max-width: 1459px) {
    flex: 0 0 calc(100% / 4 - 8px);
  }

  @media (max-width: 1039px) {
    flex: 0 0 31.5%;
  }

  @media (max-width: 768px) {
    flex: 0 0 47.5%;
  }

  @media (max-width: 509px) {
    flex: 0 0 94%;
  }
`

interface EmblaProps {
  children: React.ReactNode
}

function EmblaCarousel({children}: EmblaProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: 'start',
    containScroll: 'trimSnaps',
  })
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])

  return (
    <>
      <ButtonWrapper>
        <Button
          className={cn('embla__prev', {disabled: prevBtnDisabled})}
          onClick={() => emblaApi.canScrollPrev() && emblaApi.scrollPrev()}
          disabled={prevBtnDisabled}
        >
          <ArrowLeftSLineIcon />
        </Button>

        <Button
          className={cn('embla__next', {disabled: nextBtnDisabled})}
          onClick={() => emblaApi.canScrollNext() && emblaApi.scrollNext()}
          disabled={nextBtnDisabled}
        >
          <ArrowRightSLineIcon />
        </Button>
      </ButtonWrapper>

      <Carousel className="embla" ref={emblaRef}>
        <EmblaContainer>{children}</EmblaContainer>
      </Carousel>
    </>
  )
}

export default function WishlistSlider({
  title,
}: Page_page_content_WishlistSliderRecord): JSX.Element {
  const {wishlistItems, isValidating} = useWishlist()
  const {user} = useAuth()

  const {data: userPrices, loading: userPriceLoading} = useUserPrices(
    wishlistItems?.map((w) => w.sku),
  )

  const len = wishlistItems?.length
  const isLoading = !wishlistItems && isValidating

  if (!user) {
    return null
  }

  return (
    <BlockWrapper>
      <Container>
        <Wrapper>
          <TitleWrapper>
            <LeftWrapper>
              <Title>
                <span>{len === 1 ? 'Vara' : 'Vörur'}</span>
                <span> úr óskalistanum þínum</span>
              </Title>

              <Count>({len})</Count>
            </LeftWrapper>

            <TextLink href="/oskalisti">Sjá allar vörur</TextLink>
          </TitleWrapper>

          <CarouselContainer className="embla_viewport">
            {isLoading ? (
              <EmblaCarousel>
                {[...Array(6)].map((_, i) => (
                  <EmblaSlider key={i}>
                    <ProductCardLoader />
                  </EmblaSlider>
                ))}
              </EmblaCarousel>
            ) : wishlistItems?.length === 0 ? (
              <span data-cy="wishlist-empty">Óskalistinn þinn er tómur</span>
            ) : (
              <EmblaCarousel>
                {wishlistItems?.map((item) => (
                  <EmblaSlider key={item.sku}>
                    <LargeWishlistCard
                      item={item}
                      userPrice={userPrices?.[item.sku]}
                      userPriceLoading={userPriceLoading}
                      showProductActionButtons={true}
                    />
                  </EmblaSlider>
                ))}
              </EmblaCarousel>
            )}
          </CarouselContainer>
        </Wrapper>
      </Container>
    </BlockWrapper>
  )
}
