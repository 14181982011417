import React, {useEffect, useCallback} from 'react'

import {useThrottle} from 'react-use'
import styled, {css} from 'styled-components'
import {palette, theme} from 'styled-tools'
import {media} from 'styled-bootstrap-grid'
import transition from 'styled-transition-group'

import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '@festi/common/components/buttons'

const fixedContainer = css`
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: calc(100% - 56px);
  max-width: 560px;
  z-index: 2;
  font-size: 18px;
  font-weight: 400;
  ${fluidRange('padding-top', '20px', '24px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-bottom', '20px', '24px')};
  ${fluidRange('padding-left', '16px', '40px')};
  background-color: ${palette('backgroundGrey')};

  ${media.xs`
    width: 100%;
  `}

  @media (max-width: 425px) {
    width: 100%;
  }
`

export const DrawerHeader = styled.div`
  top: 0;
  border-bottom: 1px solid ${palette('ui10Solid')};
  ${fixedContainer}
`

export const DrawerFooter = styled.div`
  bottom: 0;
  border-top: 1px solid ${palette('ui10Solid')};
  ${fixedContainer}
`

const DrawerContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 56px);
  max-width: 560px;
  height: -webkit-fill-available;
  min-height: 100%;
  z-index: 1;
  overflow: auto;
  outline: none;
  background-color: ${palette('backgroundGrey')};
  box-shadow: ${theme('boxShadow.modal')};
  transition: transform 0.25s;
  transition-timing-function: cubic-bezier(0.33, 0.48, 0.13, 0.9);
  -webkit-overflow-scrolling: 'touch';

  ${media.xs`
    width: 100%;
  `}

  @media (max-width: 425px) {
    width: 100%;
  }
`

const DrawerWrapper = transition.div.attrs({
  unmountOnExit: false,
  timeout: 250,
})`
  position: fixed;
  box-sizing: border-box;
  inset: 0;
  z-index: 1002;
  background-color: ${palette('ui60')};

  /* Safari fix for background flickering */
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1;

  &:enter {
    opacity: 0;

    ${DrawerContent} {
      transform: translateX(100%);
    }
  }

  &:enter-active {
    opacity: 1;
    transition: opacity 0.25s;

    ${DrawerContent} {
      transform: 0;
    }
  }

  &:exit {
    opacity: 1;

    ${DrawerContent} {
      transform: 0;
    }
  }

  &:exit-active {
    opacity: 0;
    transition: opacity 0.25s;

    ${DrawerContent} {
      transform: translateX(100%);
    }
  }
`

interface Props {
  isOpen?: boolean
  children?: React.ReactNode
  showCloseBtn?: boolean
  onRequestClose?: () => void
}

export default function SideDrawer({
  isOpen,
  children,
  showCloseBtn,
  onRequestClose,
}: Props): JSX.Element {
  const onKeyDown = useCallback(
    ({key}: KeyboardEvent) => {
      if (key === 'Escape' && !!onRequestClose) {
        onRequestClose()
      }
    },
    [onRequestClose],
  )

  useEffect(() => {
    if (isOpen) {
      // Animating in
      document.body.style.overflow = 'hidden'
    } else {
      // Animating out
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown)

      return () => window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  function shouldClose({target}: React.BaseSyntheticEvent): void {
    if (target?.id === 'drawer-wrapper' && !!onRequestClose) {
      onRequestClose()
    }
  }

  const display = useThrottle(isOpen, 50)

  return (
    <DrawerWrapper
      id="drawer-wrapper"
      in={isOpen}
      aria-expanded={isOpen}
      onClick={shouldClose}
      style={{display: display ? 'block' : 'none'}}
    >
      <DrawerContent>
        {!!onRequestClose && showCloseBtn && (
          <CloseButton onClick={onRequestClose} />
        )}

        <div>{children}</div>
      </DrawerContent>
    </DrawerWrapper>
  )
}
