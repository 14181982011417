import {palette} from 'styled-tools'
import styled, {css} from 'styled-components'

import {H5} from '@festi/common/components/typography'

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid ${palette('border')};
`

const Description = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 36px 16px 16px;
`

const FunctionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
`

const ActionButtons = styled.div`
  display: flex;
  gap: 20px;
`

const animation = css`
  position: relative;
  overflow: hidden;
  background-color: #e6e7ee66;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      #f6f7f900 0%,
      #e6e7eeaf 50%,
      #e6e7ee66 100%
    );
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      left: -100%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const ImageLoader = styled.div`
  width: 140px;
  height: 140px;
  ${animation}
`

const TextLoader = styled.div`
  width: 100%;
  height: 20px;
  ${animation}
`

const PriceLoader = styled.div`
  width: 80px;
  height: 40px;
  ${animation}
`

const ButtonLoader = styled.div`
  width: 40px;
  height: 40px;
  ${animation}
`

interface Props {
  horizontal?: boolean
}

export default function ProductCardLoader({horizontal}: Props) {
  return (
    <CardWrapper style={{flexDirection: horizontal ? 'row' : 'column'}}>
      <Description style={{flexDirection: horizontal ? 'row' : 'column'}}>
        <ImageWrapper>
          <ImageLoader />
        </ImageWrapper>

        <div>
          <H5 withGutter>
            <TextLoader style={{width: 160}} />
          </H5>
          <TextLoader style={{width: 100, height: 16, marginBottom: 20}} />
          <TextLoader style={{height: 40}} />
        </div>
      </Description>

      <FunctionWrapper style={{flexDirection: horizontal ? 'column' : 'row'}}>
        <PriceWrapper>
          <PriceLoader />
        </PriceWrapper>

        <ActionButtons>
          <ButtonLoader />
          <ButtonLoader />
        </ActionButtons>
      </FunctionWrapper>
    </CardWrapper>
  )
}
