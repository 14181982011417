import {isValidPhoneNumber} from 'libphonenumber-js'
import {Col, Row} from 'styled-bootstrap-grid'
import {useUpdateEffect} from 'react-use'

import {PostalCode} from '@festi/common/api/rest'
import {
  FormikInput,
  FormikCleaveInput,
  SsnInput,
} from '@festi/common/components/form'
import {isValidSsn, parsePhoneNumber} from '@festi/utils/strings'

import PostalCodeSelect from './PostalCodeSelect'
import useRegistry from '@festi/common/utils/useRegistry'

export interface AddressFormValues {
  ssn: string
  name: string
  company: string
  address: string
  apartment: string
  country: PostalCode
  phoneNumber: string
}

export interface DutyFreeAddressFormValues {
  ssn: string
  name: string
  phoneNumber: string
}

export interface AddressFormErrorProps {
  ssn?: string
  name?: string
  address?: string
  country?: string
  phoneNumber?: string
}

export interface BillingAddressFormValues {
  billingSsn: string
  billingName: string
  billingCompany: string
  billingAddress: string
  billingApartment: string
  billingCountry: PostalCode
  billingPhoneNumber: string
}

export interface BillingAddressFormErrorProps {
  billingSsn?: string
  billingName?: string
  billingAddress?: string
  billingCountry?: string
  billingPhoneNumber?: string
}

export function validateAddressFormFields(
  {ssn, name, address, country, phoneNumber}: AddressFormValues,
  isAnonymous: boolean,
): AddressFormErrorProps {
  const errors: AddressFormErrorProps = {}

  if (!ssn && !isAnonymous) {
    errors.ssn = 'Hér vantar kennitölu'
  } else if (!isValidSsn(ssn) && !isAnonymous) {
    errors.ssn = 'Ógild kennitala'
  } else if (!isValidSsn(ssn) && isAnonymous && ssn.length > 0) {
    errors.ssn = 'Ógild kennitala'
  }
  if (!name) {
    errors.name = 'Hér vantar nafn'
  }
  if (!address) {
    errors.address = 'Hér vantar heimilisfang'
  }
  if (!country?.code) {
    errors.country = 'Hér vantar stað'
  }
  if (!phoneNumber) {
    errors.phoneNumber = 'Hér vantar símanúmer'
  } else if (parsePhoneNumber(phoneNumber).length !== 11) {
    errors.phoneNumber = 'Símanúmer ekki á réttu formi'
  } else if (
    (phoneNumber.startsWith('+') && !phoneNumber.startsWith('+354')) ||
    (phoneNumber.startsWith('00') && !phoneNumber.startsWith('00 354'))
  ) {
    errors.phoneNumber = 'Forskeyti ekki rétt'
  } else if (!isValidPhoneNumber(phoneNumber, 'IS')) {
    errors.phoneNumber = 'Ekki leyfilegt símanúmer'
  }

  return errors
}

export function validateBillingAddressFormFields(
  {
    billingSsn,
    billingName,
    billingAddress,
    billingCountry,
    billingPhoneNumber,
  }: BillingAddressFormValues,
  isAnonymous: boolean,
): BillingAddressFormErrorProps {
  const errors: BillingAddressFormErrorProps = {}

  if (!billingSsn && !isAnonymous) {
    errors.billingSsn = 'Hér vantar kennitölu'
  } else if (!isValidSsn(billingSsn) && !isAnonymous) {
    errors.billingSsn = 'Ógild kennitala'
  } else if (!isValidSsn(billingSsn) && isAnonymous && billingSsn.length > 0) {
    errors.billingSsn = 'Ógild kennitala'
  }
  if (!billingName) {
    errors.billingName = 'Hér vantar nafn'
  }
  if (!billingAddress) {
    errors.billingAddress = 'Hér vantar heimilisfang'
  }
  if (!billingCountry?.code) {
    errors.billingCountry = 'Hér vantar stað'
  }
  if (!billingPhoneNumber) {
    errors.billingPhoneNumber = 'Hér vantar símanúmer'
  } else if (parsePhoneNumber(billingPhoneNumber).length !== 11) {
    errors.billingPhoneNumber = 'Símanúmer ekki á réttu formi'
  } else if (
    (billingPhoneNumber.startsWith('+') &&
      !billingPhoneNumber.startsWith('+354')) ||
    (billingPhoneNumber.startsWith('00') &&
      !billingPhoneNumber.startsWith('00 354'))
  ) {
    errors.billingPhoneNumber = 'Forskeyti ekki rétt'
  } else if (!isValidPhoneNumber(billingPhoneNumber, 'IS')) {
    errors.billingPhoneNumber = 'Ekki leyfilegt símanúmer'
  }

  return errors
}

interface Props {
  ssn?: string
  isBilling?: boolean
  businessForm?: boolean
  handleNameChange?: (type: 'name' | 'billingName', value: string) => void
}

export default function AddressFormFields({
  ssn,
  isBilling = false,
  businessForm,
  handleNameChange,
}: Props): JSX.Element {
  const {ssnValid, registryName, registryMessage} = useRegistry(ssn)

  const validText = ssnValid ? registryName : registryMessage

  useUpdateEffect(() => {
    !!handleNameChange &&
      handleNameChange(isBilling ? 'billingName' : 'name', registryName)
  }, [registryName])

  return (
    <Row>
      <Col col={12} md={6}>
        <SsnInput
          name={isBilling ? 'billingSsn' : 'ssn'}
          placeholder="Kennitala"
          value={ssn}
          info="Með því að velja að skrá kennitölu er hægt að fletta upp þessum viðskiptum í kerfum ELKO auk þess sem kaupnóta verður aðgengileg á mínum síðum þannig að ekki þurfi að geyma afrit af nótunni til að framvísa við skil, viðgerðir eða önnur þjónustumál."
          link="https://elko.is/um_elko/personuvernd"
          linkLabel="Sjá nánar í persónuverndarstefnu"
          highlight={{
            text: validText,
            error: !ssnValid,
          }}
        />
      </Col>
      <Col col={12} md={6}>
        <FormikInput
          name={isBilling ? 'billingName' : 'name'}
          placeholder="Nafn"
          characterLimit={50}
        />
      </Col>
      <Col col={12} md={6}>
        <FormikCleaveInput
          name={isBilling ? 'billingPhoneNumber' : 'phoneNumber'}
          placeholder="Símanúmer"
          options={{phone: true, phoneRegionCode: 'IS'}}
        />
      </Col>
      {!businessForm && (
        <Col col={12} md={6}>
          <FormikInput
            name={isBilling ? 'billingCompany' : 'company'}
            placeholder="Fyrirtæki (ef á við)"
            characterLimit={50}
          />
        </Col>
      )}
      <Col col={12} md={6}>
        <FormikInput
          name={isBilling ? 'billingAddress' : 'address'}
          placeholder="Heimilisfang"
          characterLimit={50}
        />
      </Col>
      <Col col={12} md={6}>
        <FormikInput
          name={isBilling ? 'billingApartment' : 'apartment'}
          placeholder="Íbúð nr. / Hæð"
          characterLimit={30}
        />
      </Col>
      <Col col={12}>
        <PostalCodeSelect name={isBilling ? 'billingCountry' : 'country'} />
      </Col>
    </Row>
  )
}
