import React from 'react'

import {Col, Container, media, Row} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {prop, ifProp, palette} from 'styled-tools'

import {Page_page_content_TextBlockRecord as TextBlockType} from '@festi/common/api/datocms/types/Page'
import {DatoStructuredText} from '@festi/common/components/common'
import {H2} from '@festi/common/components/typography'
import {styledTheme as theme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'

import {BlockWrapper} from '../layout'

const newDesign = settings.redesign

interface TextImageProps {
  imgUrl: string
}

interface TextWrapperProps {
  inContainer: boolean
  darkMode?: boolean
}

interface MirroredProps {
  mirrored: boolean
}

const TextImage = styled.div<TextImageProps>`
  ${fluidRange('min-height', '200px', '400px')}
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0;
    top: 0;
    background-image: url(${prop('imgUrl')});
    background-position: center;
    background-size: cover;
    border-radius: ${newDesign ? '12px' : 'unset'};
  }
`

const containerCSS = css`
  ${fluidRange('padding', '24px', '75px')};
`

const borderCSS = css`
  border-top: 14px solid ${palette('green')};
`

const TextWrapper = styled.div<TextWrapperProps>`
  position: relative;
  ${(props) => props.inContainer && containerCSS};
  ${(props) => props.inContainer && props.darkMode && borderCSS};
`

const Text = styled.div<MirroredProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 630px;
  height: 100%;

  ${media.sm`
    ${ifProp({mirrored: true}, 'padding-left: 30px', 'padding-right: 30px')};
  `}
`

const TextContainer = styled.div`
  max-width: 1344px;
  margin: auto;
`

export default function TextBlock({
  title,
  content,
  image,
  inContainer = false,
  mirrored = false,
  darkMode = false,
}: TextBlockType): JSX.Element {
  const style = {backgroundColor: 'transparent'}
  if (inContainer && darkMode) {
    style.backgroundColor = theme.palette.blue
  } else if (inContainer) {
    style.backgroundColor = theme.palette.ui5
  }

  return (
    <BlockWrapper darkMode={!inContainer && darkMode}>
      <Container>
        <TextContainer>
          <TextWrapper
            inContainer={inContainer}
            style={style}
            darkMode={darkMode}
          >
            <Row>
              <Col
                col={12}
                sm={6}
                smOrder={mirrored ? 'last' : 'first'}
                smOffset={image ? 0 : 3}
              >
                <Text mirrored={mirrored}>
                  <H2 withGutter darkMode={darkMode}>
                    {title}
                  </H2>
                  <DatoStructuredText content={content} darkMode={darkMode} />
                </Text>
              </Col>
              {!!image && (
                <Col col={12} sm={6}>
                  <TextImage imgUrl={image?.responsiveImage.src} />
                </Col>
              )}
            </Row>
          </TextWrapper>
        </TextContainer>
      </Container>
    </BlockWrapper>
  )
}
