import React, {useEffect, useCallback, useState, useRef} from 'react'

import {FocusTrapWrapper} from '.'
import cn from 'classnames'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, theme} from 'styled-tools'
import transition from 'styled-transition-group'

import settings from '@festi/common/constants/settings'

import {styledTheme} from '../../themes'
import {fluidRange} from '../../utils/styles'

const redesign = settings.redesign

const DrawerContent = styled.div`
  position: absolute;
  top: ${styledTheme.navBarHeight.small};
  right: 0;
  width: calc(100% - 56px);
  max-width: 560px; //new-design
  /* max-width: 400px; */
  height: -webkit-fill-available;
  min-height: calc(100% - ${styledTheme.navBarHeight.small});
  overflow: auto;
  outline: none;
  /* background-color: ${palette('white')}; */
  background-color: ${palette('backgroundGrey')}; //new-design
  box-shadow: ${theme('boxShadow.modal')};
  transition: transform 0.25s;
  transition-timing-function: cubic-bezier(0.33, 0.48, 0.13, 0.9);
  -webkit-overflow-scrolling: 'touch';

  &.newDesign {
    top: 0;
    min-height: 100vh;
  }

  &.onDutyFreeFrontPage:not(.newDesign) {
    top: ${styledTheme.navBarHeight.dutyFreeFrontPageSmall};
    min-height: calc(100% - ${styledTheme.navBarHeight.dutyFreeFrontPageSmall});
  }

  ${media.xs`
    width: 100%;
  `}

  @media (max-width: 425px) {
    width: 100%;
  }

  ${media.md`
    top: ${styledTheme.navBarHeight.large};
    min-height: calc(100% - ${styledTheme.navBarHeight.large});

    &.onDutyFreeFrontPage:not(.newDesign) {
      top: ${styledTheme.navBarHeight.dutyFreeFrontPageLarge};
      min-height: calc(100% - ${styledTheme.navBarHeight.dutyFreeFrontPageLarge});
    }
  `}
`

const CloseButton = styled.button`
  background: none;
  border-radius: 0;
  padding: 16px;
  border: 0;
  position: absolute;
  ${fluidRange('top', '0px', '16px')}
  ${fluidRange('left', '0px', '16px')}
  cursor: pointer;

  svg {
    display: block;
  }
`

const DrawerWrapper = transition.div.attrs({
  unmountOnExit: true,
  timeout: 250,
})`
  position: fixed;
  overflow-y: none;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${redesign ? 1002 : theme('zIndex.sideDrawer')};
  background-color: ${palette('ui60')};

  /* Safari fix for background flickering */
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1;

  &:enter {
    opacity: 0;

    ${DrawerContent} {
      transform: translateX(100%);
    }
  }

  &:enter-active {
    opacity: 1;
    transition: opacity 0.25s;

    ${DrawerContent} {
      transform: 0;
    }
  }

  &:exit {
    opacity: 1;

    ${DrawerContent} {
      transform: 0;
    }
  }

  &:exit-active {
    opacity: 0;
    transition: opacity 0.25s;

    ${DrawerContent} {
      transform: translateX(100%);
    }
  }
`

interface Props {
  isOpen?: boolean
  noFocus?: boolean
  showCloseBtn?: boolean
  onDutyFreeFrontPage?: boolean
  newDesign?: boolean
  children?: React.ReactNode
  onRequestClose?: () => void
}

export default function SideDrawer({
  isOpen,
  noFocus = false,
  children,
  newDesign: forceNewDesign = false,
  showCloseBtn,
  onDutyFreeFrontPage = false,
  onRequestClose,
}: Props): JSX.Element {
  const ref = useRef(null)
  const [openFocusTrap, setOpenFocusTrap] = useState<boolean>(false)

  const newDesign = forceNewDesign || redesign

  const onKeyDown = useCallback(
    ({key}: KeyboardEvent) => {
      if (key === 'Escape' && !!onRequestClose) {
        onRequestClose()
      }
    },
    [onRequestClose],
  )

  useEffect(() => {
    if (isOpen) {
      // Animating in
      document.body.style.overflow = 'hidden'
    } else {
      // Animating out
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown)

      return () => window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  function shouldClose({target}: React.BaseSyntheticEvent): void {
    if (target?.id === 'drawer-wrapper' && !!onRequestClose) {
      onRequestClose()
    }
  }

  return (
    <DrawerWrapper
      ref={ref}
      id="drawer-wrapper"
      in={isOpen}
      aria-expanded={isOpen}
      onClick={shouldClose}
      onEntered={() => setTimeout(() => setOpenFocusTrap(true), 250)}
      onExited={() => setTimeout(() => setOpenFocusTrap(false), 250)}
    >
      <FocusTrapWrapper isOpen={openFocusTrap && !noFocus}>
        <DrawerContent
          style={{
            maxWidth: newDesign ? 560 : 400,
            backgroundColor: newDesign
              ? styledTheme.palette.backgroundGrey
              : 'white',
          }}
          className={cn({onDutyFreeFrontPage, newDesign})}
        >
          <div>
            {!!onRequestClose && showCloseBtn && (
              <CloseButton onClick={onRequestClose} aria-label="close">
                <CloseLineIcon size={28} color={styledTheme.palette.blue} />
              </CloseButton>
            )}
          </div>

          <div>{children}</div>
        </DrawerContent>
      </FocusTrapWrapper>
    </DrawerWrapper>
  )
}
