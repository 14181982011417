import {gql} from '@apollo/client'

export const footerFragment = gql`
  fragment FooterFields on FooterRecord {
    id
    emailAddress
    emailAddressLabel
    emailAddressHref
    phoneNumber
    phoneNumberLabel
    phoneNumberHref
    footerInfoBullets {
      id
      title
      icon
      description
      link
    }
    footerDutyfreeInfoBullets {
      id
      title
      icon
      description
      link
    }
    servicePages {
      id
      path
      heading
    }
    aboutPages {
      id
      path
      heading
    }
    shopPages {
      id
      path
      heading
    }
    storefront {
      ... on ExternalLinkRecord {
        id
        heading
        path
        icon
      }
    }
    dutyfree {
      ... on ExternalLinkRecord {
        id
        heading
        path
        icon
      }
    }
    paper {
      ... on ExternalLinkRecord {
        id
        heading
        path
        icon
      }
    }
    blogg {
      ... on ExternalLinkRecord {
        id
        heading
        path
        icon
      }
    }
    socialMedia {
      ... on ExternalLinkRecord {
        id
        heading
        path
        icon
      }
    }
  }
`

const openingHoursFragment = gql`
  fragment OpeningHoursGeneralFields on OpeningHoursGeneralRecord {
    id
    stores {
      storeName
      weekDays
      saturdays
      sundays
      address
      phone
    }
    openingHoursPage {
      path
    }
    customerServiceCard {
      label
      schedule
      comment
    }
    dutyFreeCard {
      label
      schedule
      comment
    }
    onlineChatCard {
      label
      schedule
      comment
    }
  }
`

const openingHoursDutyfreeFragment = gql`
  fragment OpeningHoursDutyfreeFields on OpeningHoursDutyfreeRecord {
    id
    departureStore
    arrivalStore
    openingHoursPage
  }
`

const specialHoursFragment = gql`
  fragment SpecialHoursPeriodFields on SpecialHoursPeriodRecord {
    id
    activeFrom
    activeTo
    label
    path
  }
`

const notificationFragment = gql`
  fragment NotificationFields on NotificationRecord {
    id
    color
    headline
    description {
      value
    }
    icon
    presentation
  }
`

export const webpageQuery = gql`
  ${footerFragment}
  ${notificationFragment}
  ${openingHoursFragment}
  ${openingHoursDutyfreeFragment}
  ${specialHoursFragment}
  query Webpage {
    footer {
      ...FooterFields
    }
    notification {
      ...NotificationFields
    }
    allSpecialHoursPeriods {
      ...SpecialHoursPeriodFields
    }
    openingHoursGeneral {
      ...OpeningHoursGeneralFields
    }
    openingHoursDutyfree {
      ...OpeningHoursDutyfreeFields
    }
  }
`
